html, body {
    height: 100%;
}


#app {
    height: 100%
}

.background {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: contain;
    background: #000000 center center;
    top: 0;
    left: 0;

    #particles-js {
        background: transparent;
        background-size: contain;
        height: 100%;
        width: 100%;
    }
}

.login-card {
    min-height: 320px;
    max-width: 100%;
}

.login-subline {
    margin-bottom: 15px;
}


.login-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-subtitle-headline {
    margin-top: 5%;
    color: white;
    font-weight: 400;
    text-align: left;
    font-size: 1.1em;
    letter-spacing: 0;
    line-height: 1;
    white-space: nowrap;
    width: 100%;
    transform: translateX(-0.2%);
}

.login-btn-row {
    margin-top: 30px;
}

.login-btn {
    font-size: 14px;
    cursor: pointer;
}

.login-headline {
    margin-bottom: 30px;
}

.login-input {
    height: calc(2em + 1rem + 2px);
    font-size: medium;
}

.login-invalid-feedback {
    font-size: small;
    height: 20px;
}

.login-help-feedback {
    font-size: small;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-center {
    justify-content: center;
}

.vertical-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.login-input-height {
    min-height: 100px;
}

.no-padding {
    padding: 0;
}

.mfa-center {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
