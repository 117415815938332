.rn-list-group {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    /*.rn-list-item {
        border: none;
        border-left: 1px solid #DEE4E6 !important;
        border-bottom: 1px solid #DEE4E6;

        &:last-child {
            border-bottom: none;
        }
    }*/
}
