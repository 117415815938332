#body-row {
    margin-left: 0;
    margin-right: 0;
}

#sidebar-container {
    min-height: calc(100vh - 51px);
    background-color: #ffffff;
    margin-top: 50px;
    padding: 0;

}

.sidebar-expanded {
    width: 100%;
}

.sidebar-collapsed {
    /*width: 60px;*/
    width: 100px;
}

/* ----------| Menu item*/
#sidebar-container .list-group a {
    height: 50px;
    color: black;
}

/* ----------| Submenu item*/
#sidebar-container .list-group li.list-group-item {
    background-color: #ffffff;
}

#sidebar-container .list-group .sidebar-submenu a {
    height: 45px;
    padding-left: 30px;
}

.sidebar-submenu {
    font-size: 0.9rem;
}


.adminarea-list-group-item {
    border: 0;
    background-color: #ffffff;

    &:hover {
        background: rgba(0, 0, 0, 0.04);
        box-shadow: inset 4px 0 0 #666;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
    }
}

.adminarea-list-group-item-headline {
    border: 0;
    background-color: #ffffff;
}

.adminarea-height {
    height: calc(100vh - 51px);
    margin-top: 50px;
    overflow: auto;
}

.adminarea-container {
    margin-top: 20px;
}

.b-card-adminarea {
    max-height: calc(100vh - 100px);
}

.list-group-overflow {
    height: 100%;
    overflow: auto;
}

.table-overflow {
    max-height: calc(100vh - 500px);
    overflow: auto;
}

.modal-height {
    max-height: calc(100vh - 300px);
    overflow: auto;
}

.password-height {
    min-height: 1000px;
    max-height: calc(100vh - 300px);
    overflow: auto;
}

.component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
}

.component-fade-enter, .component-fade-leave-to {
    opacity: 0;
}
