.rn-no-border {
    border: unset;
}

.rn-scroll-container {
    height: 40vh;
    overflow: hidden;
    overflow-y: scroll;
}

.rn-pointer {
    cursor: pointer;
}

.rn-list-item-selection {
    color: $white !important;
    background-color: $primary !important;

    small {
        color: $white !important;
    }
}

.rn-table-checkbox-pointer-events {
    z-index: 0;
    pointer-events: none;
}

.rn-btn-add-scan-tasks {
    transform: translateY(-11%);
}

.rn-loading-bar {
    height: 25px;
    width: 50px;
}

.rn-no-border {
    border: unset;
}

.rn-collapse-icon {
    .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
}

.rn-task {
    height: 600px;
}
.rn-task-finish {
    height: 600px;
    .card-body {
        overflow-y: auto;
    }
}


.rn-progress-bar-label {
    position: absolute;
    width: 100%;
    color: $secondary;
    font-size: small;
    margin: 1px;
}

.rn-hide-clear {
    height: 2.5rem !important;
}

input.rn-hide-clear[type=search]::-ms-clear,
input.rn-hide-clear[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

input.rn-hide-clear[type="search"]::-webkit-search-decoration,
input.rn-hide-clear[type="search"]::-webkit-search-cancel-button,
input.rn-hide-clear[type="search"]::-webkit-search-results-button,
input.rn-hide-clear[type="search"]::-webkit-search-results-decoration {
    display: none;
}
