.mail-item-table {
    width: 30%;
}

.mail-type-table {
    width: 18%;
}

.mail-holddown-count-table {
    width: 5%;
}

.mail-mail-table {
    width: 5%;
}

.mail-action-table {
    width: 12%;
}

.item-disable-table {
    width: 5%;
}

.cell-height {
    /* min-height: 50px;
     display: flex;
     flex-direction: column;
     justify-content: center;*/
}

.temperature-badge {
    font-size: 0.54rem;
}
