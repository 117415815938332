$primary: #7797A3;
$danger: #E51A4B;
$secondary: #2E3B46;
$black: #1A2026;
$success: #32A38C;
$blue: #337FA5;
$grey1: #7797A3;
$primaryGrey: #444444;
$secondaryRed: #E51A4B;
$red: #990000;
$greyWidget: #ababab;
$white: #ffffff;

@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';
@import "./scss/login";
@import "./scss/adminarea";
@import "./scss/dashboard";
@import "./scss/navbar";
@import "./scss/widget";
@import "./scss/table";

@import "../vue_inventory/scss/rn-stepper";
@import "../vue_inventory/scss/rn-stepper-progress";
@import "../vue_inventory/scss/rn-stepper-step";
@import "../vue_inventory/scss/rn-list-group";
@import "../vue_inventory/scss/rn-global-styles";
@import "../vue_inventory/scss/rn-card";

a {
    cursor: pointer;
}

*:focus {
    outline: none !important;
}

body {
    margin: 0;
    font-family: "Helvetica Neue", 'Arial', 'SansSerif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

#app {
    background-color: rgba(0, 0, 0, 0.02);

    .card {
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) !important;
    }
}

.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.row > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.modal-header, .card-header, .card, .btn, .dropdown, input, .form-control, .list-group, .list-group-item, .nav-pills .nav-link, .pagination, .b-pagination, .modal-content, .dropdown-menu, .custom-select {
    border-radius: unset !important;
}

.modal-header, .card-header {
    border-bottom: unset !important;
}

#map {
    width: auto;
    height: 500px;
}

.navbar-logo {
    max-height: 96px;
    max-width: 96px;
    transform: translateY(-2px);
}


.card-header {
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.breadcrumb {
    background-color: #FBFBFB;
}

.fix-banner {
    background-color: #FBFBFB;
    position: fixed;
    width: 100%;
    padding: 50px 0;
    margin: 20px 0;
    height: 51px;
    z-index: 45;

    .banner-button {
        display: flex;
        align-self: flex-end;
        margin-bottom: 35px;
    }
}


.open-fullscreen {
    width: 55px;
    height: 35px;
    position: absolute;
    cursor: n-resize;
    top: 0;
    right: 0;
    margin-top: 5px;
}

#marker {
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.map-card-widget {
    margin-top: 150px;

    .card-body {
        padding: 0;
    }
}

.full-height {
    height: 100%;
}

.dashboard-wrapper {
    padding: 50px 0;
}

.mapboxgl-marker {
    text-transform: none;
    text-decoration: none;
    color: black;


    .maker-name {
        width: max-content;
        position: absolute;
        transform: inherit;
        top: 36px;
    }
}

.maker-container {
    height: 50px;
    width: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


.maker-icon-online {
    position: absolute;
    background-image: url('./images/marker+2ba48c.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.16);
    height: 45px;
    width: 45px;

    .hover-text {
        background-color: white;
        padding: 2px;
        text-align: center;
        font-size: 1.3vw;
        transform: translate(-25%, -100%);
        width: 200px;
        color: black;
        text-decoration: none;
        opacity: 0;
    }

    &:hover {
        .hover-text {
            z-index: 9999;
            opacity: 0.75;
            transition-delay: 0.4s;
        }
    }
}

.maker-icon-offline {
    position: absolute;
    background-image: url('./images/marker+e51a4b.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.16);
    height: 45px;
    width: 45px;

    .hover-text {
        background-color: white;
        padding: 2px;
        text-align: center;
        font-size: 1.3vw;
        transform: translate(-25%, -100%);
        width: 200px;
        color: black;
        text-decoration: none;
        opacity: 0;
    }

    &:hover {
        .hover-text {
            z-index: 9999;
            opacity: 0.75;
            transition-delay: 0.4s;
        }
    }
}


.result-list {
    position: absolute;
    margin-top: 5px;
    z-index: 20;
    width: 94%;
    box-shadow: 10px 10px 18px -10px rgba(0, 0, 0, 0.75);
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.device-groups {
        overflow-y: auto;
        max-height: 40vh;
        margin-top: 40px;
    }

.ml-center {
    justify-content: center;
    align-content: center;
    width: 100%;

    .input-group {
        width: 60%;
    }

    .result-list-searchbar {
        position: absolute;
        top: 60px;
        z-index: 20;
        width: 38%;
        border: 1px solid rgba(0, 0, 0, 0.125);
        max-height: 45vh;
        overflow: scroll;
    }
}

.result-list-notification {
    position: absolute;
    top: 60px;
    z-index: 1020;
    min-width: 30%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    max-height: 45vh;
    overflow: scroll;
    right: 192px;
}

.modal-header-device {
    width: 100%;

    h5 {
        margin: 0;
    }
}

.card-overflow {
    height: 350px;

    .card-inner-overflow {
        overflow: scroll;
        height: 100%;
    }
}

.width-100 {
    width: 100%;
}

.racksnet-dropdown-multiselect {
    ul {
        overflow: scroll;
        height: 300px;
    }
}

.scroll-table-racksnet {
    max-height: 200px;
    overflow: scroll;
}


.scroll-container {
    height: 500px;
    overflow: scroll;
}

.scroll-snmp-container {
    height: 420px;
    overflow-y: scroll;
}

.scroll-snmp-mail-container {
    height: 295px;
    overflow-y: scroll;
}

.rts-devices-list-selectable {
    padding: 0.75rem 1.25rem 0.75rem 0.25rem
}

.tab-bordered-header > div > ul {
    border-bottom: 2px solid #7797A3;
}

.tab-bordered-header > div > ul > .nav-item {
    border: 1px solid rgba(0,0,0,0.1);
    border-bottom: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.tab-bordered-header > div > ul > .nav-item:hover {
    border: 1px solid rgba(119, 151, 163, 0.5);
    border-bottom: none;
}

.snmp-view-list {
    .list-group {
        &.has-padding {
            .list-group-item {
                padding-right: 0.75rem;
            }
        }

        &.no-padding {
            .list-group-item {
                padding-right: 0;
            }
        }
    }
}

.red-alert {
    small {
        color: $secondaryRed !important;
    }
}

.sidebar-divider {
    width: 100%;
    height: 3px;
    background: rgba(26, 32, 38, 0.125);
    margin: 0.75rem 0;
}

.spinner-background {
    position: absolute;
    height: 100%;
    width: 97%;
    pointer-events: none;
    z-index: 1;

    .spinner-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.pointer-events-none {
    pointer-events: none;
}

.spinner-background-widget {
    position: absolute;
    left: 50%;
    top: 50%;
    pointer-events: none;
    z-index: 1;
    transform: translate(-40%);

    .spinner-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.navbar {
    height: 50px;
    position: fixed;
    background-color: #f1f1f7;
    width: 100%;
    z-index: 99;
}

.folder-hover {
    background-color: #f8f9fa;

    .list-group-item {
        background-color: #f8f9fa;
    }
}

.disable-folder-hover {
    background-color: rgba(153, 0, 0, .125);
    pointer-events: none !important;

    .list-group {
        background-color: white;

        .list-group-item {
            background-color: rgba(153, 0, 0, .125);
        }
    }
}

.user-select-none {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.alert-container-box {
    position: absolute;
    z-index: 99;
    width: 100%;
    left: 33%;
    top: 1%;
}

* {
    box-sizing: border-box;
}

.dashboard-racksnet {
    .col-grow, .row {
        flex-grow: 1;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        $breakpoint: 1000px;
        --multiplier: calc(#{$breakpoint} - 100%);

        .col-grow {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-basis: calc(var(--multiplier) * 999);
            max-width: 100%;
        }
    }
}

.resizer {
    position: absolute;
    transform: translate(50%, 55%);
    cursor: row-resize;
    z-index: 40;
    bottom: 0;
    right: 50%;

    &.on-drag-map {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
        width: 150px;
        pointer-events: all;
        background-color: rgba(0, 0, 0, 0.125);
        border-radius: 65px;
        transition: background-color 1000ms;
    }
}

.widget-headline-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;

    .widget-headline {
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 1.3rem;
        margin-bottom: 0;
        word-break: break-word !important;
        overflow-wrap: break-word !important;
        margin-right: 2%;
    }

    .widget-headline-icon {
        color: #ffffff;
        font-size: 1rem !important;
    }
}

.license-number {
    border-bottom: 1px solid #DEE4E6;

    p {
        text-align: end;
        margin: 1rem 0 0 0;
    }

    h1 {
        text-align: end;
        font-weight: 400;
        margin-bottom: 0;
        font-size: 1.8rem;
    }
}

.available {
    color: $greyWidget;
}

.widget-headline-btn {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;

    &:hover {
        background-color: transparent;
        border-color: transparent;
    }
}

.widget-headline-dropdown {
    button {
        background-color: transparent;
        border-color: $white;

        &:hover {
            background-color: transparent;
            border-color: $white;
        }
    }
}

.icon-btn {
    color: $white;
}


.hover-btn-monitoring {
    color: $primary;

    &:hover {
        color: #ffffff
    }
}

.navbar-dropdown {
    a {
        color: $primaryGrey !important;
    }
}

.login-text-info-mfa {
    font-size: 0.5vw;
}

.form-control {
    &.is-valid {
        border: 1px solid #ced4da;

        &:focus {
            border-color: #c3d1d6;
            box-shadow: 0 0 0 0.2rem rgba(119, 151, 163, 0.25);
        }
    }
}

.modal-title {
    font-weight: 400;
}

.scroll-class {
    height: 400px;
    overflow: auto;
}

.custom-control-label {
    align-items: center;
}

.white-space-icon {
    color: $white;
    font-size: 1.2rem;
    position: absolute;
    transform: translate(-1.5px, -1.4px);
}

.overlay-icon {
    z-index: 99 !important;
}

.overlay-icon-widget {
    position: absolute;
    z-index: 40 !important;
    top: 0;
    left: 0;
    font-size: 20px !important;
    transform: translate(-11.2%, -21.4%);
}

.icon-container {
    display: inline-flex;
}

.title-modal-headline {
    display: inline-flex;
    margin-left: 5px;
}


.white-space-icon-widget {
    color: $white;
    font-size: 22.4px !important;
    position: absolute;
    z-index: 1 !important;
    top: 0;
    left: 0;
    transform: translate(-14.8%, -23.4%);
}

.headline-btn-widget {
    cursor: none;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
}


.access-color {
    color: #2ddb8f;
}
.core-color {
    color: #51857b
}
.rack-color {
    color: #c98921;
}
.server-color {
    color: #6f4332;
}
.storage-color {
    color: #A99924
}
.tc-color {
    color: #337FA5;
}
.wlanctrl-color {
    color: #af783e;
}
.ap-color {
    color: #1a0e9a
}
.autonom_ap-color {
    color: #75C2B2;
}
.unknown-color {
    color: #68205c;
}

.double-icon-btn {
    width: 26px;
    position: relative;
    height: 100%;
}

.chart-wrapper-widget {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.license-first-row {
    display: flex;
    align-items: center;
}

.license-second-row {
    display: flex;
    align-items: flex-start;
}

.widget-headline-wrapper {
    flex-shrink: 0;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.register-card {
    height: 650px;
}

.cpu-name {
    text-align: end;
    margin-right: 18px;
}

.center-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 0.8rem;
}

#modal-result-backup {
    .modal-body {
        height: 600px;
        overflow: auto;
    }
}

.center-timer-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 0.8rem;
}

.dropdown-width .dropdown-menu {
    width: 100%;
}


.scroll-dropdown .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
}


.dropdown-width .dropdown-menu {
    width: 100%;
}

.scroll-dropdown .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
}

.hover-restore {
    background-color: $primary;
    color: $white;

    .trap-view {
        color: $white;

        small {
            color: $white !important;
        }
    }

    .btn-outline-secondary {
        border: 1px solid $white;

        svg {
            color: $white;
        }
    }
}

.hover-interface-traps {
    background-color: #efefef;
    color: #6c757d;

    .trap-view {
        color: #6c757d;

        small {
            color: #6c757d !important;
        }
    }

    .btn-outline-secondary {
        border: 1px solid #6c757d;

        svg {
            color: #6c757d;
        }
    }
}

.firmware-update-modal {

}

/*.firmware-update-container-modal {
    height: 60vh;
}*/

.display-none {
    display: none;
}

.rn-no-border {
    border: unset;
}

.rn-scroll-container {
    height: 40vh;
    overflow: hidden;
    overflow-y: scroll;
}

.rn-pointer {
    cursor: pointer;
}

.overview-side {
    height: 100%;
    overflow: auto;
}

.firmware-modal {
    height: 65vh;
}

.firmware-device-list {
    max-height: 350px;
}


.rn-task {
    height: 450px;
}


.full-width {
    width: 100%;
}


.rn-disabled {
    pointer-events: none;
}
.test-test {
    width: 100%;
}


.calendar-form {
    min-width: 40rem;
}

.icon-rotate {
    transform: rotate(0deg);
    animation: rotate 2s linear infinite;

}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
