.rn-card {
    /*    height: 400px;*/
    /*
    border: unset;
    */

    .full-height {
        height: 100%;
    }

    .width-100 {
        width: 100%;
    }

    .rn-card-headline-container {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: center;

        .rn-card-headline {
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-size: 1.3rem;
            margin-bottom: 0;
            word-break: break-word !important;
            overflow-wrap: break-word !important;
            margin-right: 2%;
        }

        .rn-card-headline-icon {
            color: #ffffff;
            font-size: 1rem !important;
        }
    }

    .center-no-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
