#dashboard-sidebar-container {
    height: calc(100vh - 50px);
    margin-top: 50px;
    background-color: #ffffff;
    padding: 0;
    width: 330px;
    overflow-y: scroll;
    border-right: 1px solid rgba(0, 0, 0, 0.125);
}


.online-bg {
    background-color: #32A38C
}

.offline-bg {
    background-color: $secondaryRed
}

.warning-bg {
    background-color: #FFB040;
}

.light-bg {
    background-color: #ffffff
}


.online {
    color: #32A38C
}

.offline {
    color: $secondaryRed
}

.warning {
    color: #FFB040;
}

.light {
    color: #ffffff
}

.unknown {
    color: #DEE4E6;
}

.cursor-default {
    cursor: default!important;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
    display: none;
}

.map-card-widget-loading {
    margin-top: 50px;
    height: 500px;

    .card-body {
        padding: 0;
    }
}

.dashboard-overflow {
    height: calc(100vh - 50px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.resizer-sidebar {
    left: 330px;
    position: absolute;
    transform: translate(-50%, -50%);
    cursor: col-resize;
    z-index: 1000;
    top: 50%;

    &.on-drag-sidebar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
        width: 150px;
        pointer-events: all;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.125);
        border-radius: 65px;
        transition: background-color 1000ms;
    }
}


.sidebar-item {
    max-width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disable-chevron {
    pointer-events: none;
    color: #ffffff;
}

.delete-item-sidebar {
    margin-right: 8px;
}

.handle {
    cursor: move;
    margin-right: 5px;
}

.list-siderbar-item {
    border: none;
    border-left: 1px solid #DEE4E6 !important;
    border-bottom: 1px solid #DEE4E6;

    &:last-child {
        border-bottom: none;
    }
}

.list-folder-item {
    border: none;
    border-left: 1px solid #DEE4E6 !important;
    border-bottom: 1px solid #DEE4E6;
}

.no-bottom-border {
    border-bottom: none !important;
}

.list-siderbar-item-hover {
}


.icon-sidebar {
    margin-right: 8px;
}

.center-no-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.create-backup-icon {
    cursor: pointer;
}

.project-backup-list {
    border: none;
    border-bottom: 1px solid #DEE4E6;
}


button.rn-no-border {
    border: unset;
    padding: 0;
}

button.rn-no-border:hover {
    background-color: unset !important;
    border-color: unset !important;
}
