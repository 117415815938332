.rn-stepper-progress {
    display: table;
    width: 100%;
    margin: 0 auto;
    background-color: #FFFFFF;

    .rn-step {
        display: table-cell;
        position: relative;
        padding: 24px;

        &:first-child {
            .rn-step-bar-left {
                display: none;
            }
        }

        &.active {
            .rn-step-circle {
                background-color: $primary;
            }

            .rn-step-title {
                color: $primary;
            }
        }

        &:last-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            .rn-step-bar-right {
                display: none;
            }
        }

        .rn-step-title {
            margin-top: 16px;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            color: rgba(0, 0, 0, .26);
        }

        .rn-step-circle {
            width: 30px;
            height: 30px;
            margin: 0 auto;
            background-color: #999999;
            border-radius: 50%;
            text-align: center;
            line-height: 30px;
            font-size: 16px;
            font-weight: 600;
            color: #FFFFFF;
        }

        .rn-step-bar-left, .rn-step-bar-right {
            position: absolute;
            top: 36px;
            height: 1px;
            border-top: 1px solid #DDDDDD;
        }

        .rn-step-bar-left {
            left: 0;
            right: 50%;
            margin-right: 20px;
        }

        .rn-step-bar-right {
            right: 0;
            left: 50%;
            margin-left: 20px;
        }


        &.rn-active-step {

        }
    }
}
